import SupportingContent from 'layouts/SupportingContent'
import TextBlock from 'layouts/TextBlock'
import IconTextBlock from 'components/IconTextBlock'
import Link from 'basics/Link'
import { PickFirst } from 'services/utils'
import { nanoid } from 'nanoid'
import TwoUp from 'layouts/TwoUp'
import { MarketoCTA } from '../CtaWidgets/CtaWidgets'

const List = ({ textIconWidgets }) => {
  return (
    <>
      {textIconWidgets &&
        textIconWidgets.map((iconBlock) => {
          const icon = PickFirst(iconBlock.icon) || {}
          const color = PickFirst(icon.color) || {}
          const iconDetail = PickFirst(icon.icon) || {}
          return (
            <IconTextBlock
              key={nanoid()}
              svg={iconDetail.svg}
              color={color.shortName || 'blue'}
              className="mb-4"
            >
              <>
                {!iconBlock.externalLink &&
                  (!iconBlock.targetItem || iconBlock.targetItem?.length === 0) && (
                    <>
                      <strong>{iconBlock.title}</strong>
                    </>
                  )}
                {iconBlock.externalLink && (
                  <strong>
                    <Link
                      href={iconBlock.externalLink}
                      external={true}
                      className="chevron text-color"
                    >
                      {iconBlock.title}
                    </Link>
                  </strong>
                )}
                {iconBlock.targetItem && iconBlock.targetItem.length > 0 && (
                  <strong>
                    <Link
                      href={`/${PickFirst(iconBlock.targetItem)?.url || '#'}`}
                      className="chevron text-color"
                    >
                      {iconBlock.title}
                    </Link>
                  </strong>
                )}
                <div dangerouslySetInnerHTML={{ __html: iconBlock.text }} />

                {iconBlock.linkText && iconBlock.externalLink && (
                  <div className="my-2">
                    <Link href={iconBlock.externalLink || '#'} external={true} chevron={true}>
                      {iconBlock.linkText || 'Click Here'}
                    </Link>
                  </div>
                )}

                {iconBlock.targetItem && iconBlock.targetItem.length > 0 && (
                  <div className="my-2">
                    <Link href={`/${PickFirst(iconBlock.targetItem)?.url || '#'}`} chevron={true}>
                      {iconBlock.linkText || 'Click Here'}
                    </Link>
                  </div>
                )}

                {iconBlock.marketoId && (
                  <div className="my-2">
                    <MarketoCTA
                      id={iconBlock.marketoId}
                      text={iconBlock.linkText}
                      buttonType="primary"
                      message={iconBlock.marketoConfirmation} 
                    />
                  </div>
                )}
              </>
            </IconTextBlock>
          )
        })}
    </>
  )
}

const IconTextListFeature = ({ className, data }) => {
  const { headline, subheadline, textIconWidgets = [], anchor, firstColumnWidgets } = data
  const isWide = textIconWidgets.length > 5
  let config = {
    width: 'normal',
    type: 'iconList',
    gridGap: null
  }
  if (isWide) {
    config = {
      width: 'wide',
      type: 'sixtyfourty',
      gridGap: 170,
      half: (textIconWidgets.length + 1) / 2,
      firstCol: firstColumnWidgets
    }
  }
  return (
    <SupportingContent
      type={config.type}
      largeSide="right"
      containerOptions={{ width: config.width }}
      gridGap={config.gridGap}
      id={anchor}
    >
      <TextBlock align="flex-start" className="md-mb-10">
        {headline && <h2 className="mb-3 h3">{headline}</h2>}
        {subheadline && <div dangerouslySetInnerHTML={{ __html: subheadline }} />}
      </TextBlock>

      {isWide && (
        <TwoUp gridGap={64}>
          <div className="column">
            <List textIconWidgets={textIconWidgets.slice(0, config.firstCol ? config.firstCol : config.half)} />
          </div>
          <div className="column">
            <List textIconWidgets={textIconWidgets.slice(config.firstCol ? config.firstCol : config.half, textIconWidgets.length)} />
          </div>
        </TwoUp>
      )}

      {!isWide && (
        <div>
          <List textIconWidgets={textIconWidgets} />
        </div>
      )}
    </SupportingContent>
  )
}

IconTextListFeature.propTypes = {}

export default IconTextListFeature
