import PropTypes from 'prop-types'

import { ClassNames } from 'services/classname.service'
import styles from './TombstoneFeature.module.scss'
import { nanoid } from 'nanoid'
import Tombstone from 'components/Tombstone'
import FlexContainer from 'containers/FlexContainer'
import Container from 'containers/Container/Container'
import Headline from '../../design-system/src/components/Headline'
import { PickFirst } from '../../services/utils'

const TombstoneFeature = ({ className, data = {} }) => {
  const { tombstones = [], callsToAction = [] } = data
  const callToAction = PickFirst(callsToAction) || {}
  const linkedItem =
    callToAction.linkedItem && callToAction.linkedItem.length > 0
      ? callToAction.linkedItem[0]
      : null
  return (
    <Container className={ClassNames([styles['tombstone-feature'], 'mb-20 md-mb-13'])}>
      {/* <Heading level={2}>
        {data.headline}
      </Heading> */}
      <Headline
        headline={data.headline}
        subheadline={data.subheadline}
        linkText={callToAction.text}
        link={callToAction.linkUrl || linkedItem?.url}
      />
      <FlexContainer className="justify-content-center mt-8">
        {tombstones.map((block) => (
          <Tombstone key={nanoid()} block={block} size={'normal'} />
        ))}
      </FlexContainer>
    </Container>
  )
}

TombstoneFeature.propTypes = {}

export default TombstoneFeature
