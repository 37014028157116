import SupportingContent from 'layouts/SupportingContent/SupportingContent'
import ContentBlock from 'features/ContentBlock'
import { PickFirst } from 'services/utils'
import Headline from 'components/Headline'

const ContentFeatureContentBlocks = ({
  data: {
    splitRatio = [],
    leftContentBlock,
    rightContentBlock,
    width = [],
    headline,
    subheadline,
    anchor
  }
}) => {
  const { shortName = '50:50' } = PickFirst(splitRatio || []) || {}
  const { shortName: widthShortName = 'normal' } = PickFirst(width || []) || {}
  return (
    <>
      {headline && <Headline headline={headline} subheadline={subheadline} />}
      <SupportingContent type={shortName} containerOptions={{ width: widthShortName }} id={anchor}>
        {leftContentBlock && <ContentBlock data={leftContentBlock[0] || {}} />}
        {rightContentBlock && <ContentBlock data={rightContentBlock[0] || {}} />}
      </SupportingContent>
    </>
  )
}

export default ContentFeatureContentBlocks
