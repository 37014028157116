import PropTypes from 'prop-types'

import styles from './IconTextBlock.module.scss'
import { ClassNames } from '../../services/classname.service'

import Colors from '../../constants/colors'
import LargeIcon from '../../basics/LargeIcon/LargeIcon'

const IconTextBlock = ({ className, icon = 'diamond', svg, color = 'plum', children }) => {
  return (
    <div
      className={ClassNames([
        styles['ds-icon-text-block'],
        styles[`ds-icon-text-block--${Colors[color]}`],
        className
      ])}
    >
      <div className={styles['ds-icon-text-block__icon']}>
        {icon && <LargeIcon svg={svg} icon={icon} size="small" color={color} />}
      </div>
      <div className={styles['ds-icon-text-block__text']}>{children}</div>
    </div>
  )
}

IconTextBlock.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(Colors))
}

export default IconTextBlock
