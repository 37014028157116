import PropTypes from 'prop-types'
import Stat from 'components/Stat'
import GridContainer from 'containers/GridContainer'
import Container from 'containers/Container'
import FlexContainer from 'containers/FlexContainer/FlexContainer'
import Spacer from 'basics/Spacer/Spacer'
import Headline from 'components/Headline/index'

import { ClassNames } from 'services/classname.service'
import { PickFirst } from 'services/utils'
import styles from './FeaturedLayout.module.scss'

const Layouts = {
  1: {
    layout: 'normal'
  },
  2: {
    layout: 'side-by-side'
  },
  3: {
    layout: 'normal'
  },
  4: {
    layout: 'normal'
  },
  5: {
    layout: 'with-large'
  },
  6: {
    layout: 'normal'
  }
}

const LayoutWithFeature = ({ blocks, headline, subheadline, onRender }) => {
  const feature = blocks[0]
  const theRest = blocks.slice(1, blocks.length)
  return (
    <Container wide={true}>
      {headline && (
        <>
          <Headline headline={headline} />

          <Spacer size={13} />
        </>
      )}

      <GridContainer
        className={ClassNames([
          styles['featured-layout'],
          styles['featured-layout--with-large'],
          'justify-content-center'
        ])}
        gridGap={32}
      >
        <div>{onRender({ block: feature, featured: true })}</div>
        <FlexContainer gap={32} className={styles['featured-layout__the-rest']}>
          {theRest.map((block) => {
            return onRender({ block })
          })}
        </FlexContainer>
      </GridContainer>
    </Container>
  )
}

const DefaultLayout = ({ blocks, headline, subheadline, onRender }) => {
  return (
    <Container>
      {headline && (
        <>
          <Headline headline={headline} />

          <Spacer size={13} />
        </>
      )}

      <GridContainer
        gridSize={blocks.length}
        gridColumnGap={24}
        className={ClassNames([
          styles['featured-layout'],
          styles[`featured-layout--${Layouts[blocks.length]}`],
          ,
          'justify-content-center'
        ])}
      >
        {blocks.map((block, idx) => {
          const isFeatured = blocks.length === 2 ? true : false
          return onRender({ block, featured: isFeatured })
        })}
      </GridContainer>
    </Container>
  )
}

const FeaturedLayout = ({ className, headline, blocks = [], onRender }) => {
  const len = blocks.length

  return (
    <>
      {len === 5 && <LayoutWithFeature blocks={blocks} headline={headline} onRender={onRender} />}
      {len < 5 && <DefaultLayout blocks={blocks} headline={headline} onRender={onRender} />}
    </>
  )
}

FeaturedLayout.propTypes = {}

export default FeaturedLayout
