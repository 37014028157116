import PropTypes from 'prop-types'

import styles from './SupportingContentBlocks.module.scss'
import SupportingContentBlock from 'features/SupportingContentBlock'
import { nanoid } from 'nanoid'

const SupportingContentBlocks = ({ className, blocks }) => {
  return (
    <>
      {blocks &&
        blocks.map((block) => (
          <SupportingContentBlock type={block.__typename} data={block} key={nanoid()} />
        ))}
    </>
  )
}

SupportingContentBlocks.propTypes = {}

export default SupportingContentBlocks
