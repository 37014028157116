import PropTypes from 'prop-types'

import styles from './Stat.module.scss'
import { ClassNames } from '../../services/classname.service'
import Card from '../Card'

import Colors from '../../constants/colors'

const Stat = ({
  className,
  label = '',
  statOrNumber = '',
  size = 'normal',
  color = 'green',
  subscript
}) => {
  return (
    <div
      className={ClassNames([
        styles['ds-stat'],
        styles[`ds-stat--${size}`],
        styles[`ds-stat--${Colors[color]}`],
        'md-mb-5',
        className
      ])}
    >
      <Card className={ClassNames([styles['ds-card'], 'display-flex'])}>
        <div className={styles['ds-stat__value']}>{statOrNumber}</div>
        <div className={styles['ds-stat__label']} dangerouslySetInnerHTML={{ __html: label }} />
        {subscript && (
          <div className={ClassNames([styles['ds-stat__subscript'], 'mt-2 mb-4'])}>{subscript}</div>
        )}
      </Card>
    </div>
  )
}

Stat.propTypes = {
  color: PropTypes.oneOf(['plum', 'green', 'orange', 'blue', 'primaryLight']),
  size: PropTypes.oneOf(['normal', 'large']),
  statOrNumber: PropTypes.string,
  label: PropTypes.string
}

export default Stat
