import PropTypes from 'prop-types'

import styles from './Tombstone.module.scss'
import { ClassNames } from 'services/classname.service'

import Colors from 'design-system/src/constants/colors'
import Card from 'components/Card'
import CtaWidgets from 'features/CtaWidgets'
import { FormatCurrency } from 'services/utils'
import { nanoid } from 'nanoid'

const Tombstone = ({ block, className }) => {
  return (
    <div
      className={ClassNames([
        styles['tombstone'],
        styles[`tombstone--normal`],
        styles[`tombstone--${Colors[block.color ? block.color[0].shortName : 'blue']}`],
        className
      ])}
    >
      <Card className={ClassNames([styles['ds-card'], 'display-flex'])}>
        <div className={styles['ds-stat__value']}>{FormatCurrency(block.amount)}</div>
        {block.products &&
          block.products.map((product) => (
            <div key={nanoid()} className={styles['tombstone__label']}>
              {product.name}
            </div>
          ))}
        {block.productGroups &&
          block.productGroups.map((group) => (
            <div key={nanoid()} className={styles['tombstone__label']}>
              {group.name}
            </div>
          ))}
        {block.solutions &&
          block.solutions.map((solution) => (
            <div key={nanoid()} className={styles['tombstone__label']}>
              {solution.name}
            </div>
          ))}
        {block.industry &&
          block.industry.map((industry) => (
            <div key={nanoid()} className={styles['tombstone__label']}>
              {industry.name}
            </div>
          ))}
        {block.industryDetail &&
          block.industryDetail.map((detail) => (
            <div key={nanoid()} className={styles['tombstone__label']}>
              {detail.name}
            </div>
          ))}
        {block.customerLocation &&
          block.customerLocation.map((location) => (
            <div key={nanoid()} className={ClassNames([styles['location']])}>
              {location.name}
            </div>
          ))}
      </Card>
    </div>
  )
}

Tombstone.propTypes = {}

export default Tombstone
