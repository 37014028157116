import dynamic from 'next/dynamic'
import SquareImageComponent from 'basics/SquareImage' //dynamic(() => import('basics/SquareImage'), { ssr: false })
import TextBlockComponent from 'layouts/TextBlock' //dynamic(() => import('layouts/TextBlock'), { ssr: false })
import ContentFeatureContentBlocks from 'features/ContentFeatureContentBlocks' //dynamic(() => import('features/ContentFeatureContentBlocks'), {ssr: false,})
import Calltoaction from 'features/CtaBanner' //dynamic(() => import('features/CtaBanner'), { ssr: false })
import CardsContentFeature from 'layouts/CardContainer' //dynamic(() => import('layouts/CardContainer'), { ssr: false })
import TestimonialContentFeature from 'features/TestimonialContentFeature' //dynamic(() => import('features/TestimonialContentFeature'), {ssr: false,})
import ContentFeatureIconTextList from 'features/IconTextListFeature'
import StatBlock from 'features/StatBlock'
import Headline from 'components/Headline'
import ContentFeaturePeople from 'layouts/PeopleFeature' //dynamic(() => import('layouts/PeopleFeature'), { ssr: false })
import ContentFeatureTombstones from 'features/TombstoneFeature'
import ImageGrid from 'features/ImageGrid'
import BoardLinks from 'components/BoardLinks'

export const ComponentMap = {
  Image: SquareImageComponent,
  TextBlock: TextBlockComponent,
  ContentFeatureContentBlocks,
  Calltoaction,
  CardsContentFeature,
  TestimonialContentFeature,
  ContentFeatureIconTextList,
  StatFeature: StatBlock,
  Headline: Headline,
  ContentFeaturePeople,
  ContentFeatureTombstones,
  ImageGrid,
  BoardLinks
}
