import PropTypes from 'prop-types'

import styles from './PeopleGrid.module.scss'
import Container from 'containers/Container/Container'
import GridContainer from 'containers/GridContainer/GridContainer'
import PersonCard from 'components/PersonCard'
import { nanoid } from 'nanoid'
import Pagination from 'components/Pagination'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { ClassNames } from 'services/classname.service'

const PeopleGrid = ({ className = '', people = [], paginate, ourPeople = false }) => {
  const router = useRouter()
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(router.query.page ? router.query.page - 1 : 0)
  }, [router.query.page])

  const pageSize = 12
  const numberOfPages = Math.ceil(people.length / pageSize)
  const start = page * pageSize
  const end = start + pageSize
  const changePage = (page) => {
    setPage(page -1)
    document.querySelector('#people').scrollIntoView()
  }
  return (
    <Container wide={true} id="people">
      {people.length === 0 && <div className="intro-paragraph text-align-center">There are no results.</div>}
      {people.length > 0 && (
        <GridContainer
          gridGap={30}
          gridSize={people.length > 0 ? 2 : 1}
          className={ClassNames([styles['people-grid'], 'pb-6'])}
        >
          {people.slice(start, end).map((person) => (
            <PersonCard
              key={nanoid()}
              name={person.name ? person.name : `${person.firstName} ${person.lastName}`}
              title={person.title}
              company={person.company}
              locations={person.locations}
              image={person.image}
              color={person.color}
              link={person.link}
              isCutout={person.isCutout}
              shortBio={person.shortBio}
              leadership={person.leadership}
              seniorLeadership={person.seniorLeadership}
            />
          ))}
        </GridContainer>
      )}
      {paginate && (
        <Container wide={true}>
          <Pagination
            pageSize={pageSize}
            currentPage={page}
            totalPages={numberOfPages}
            onPage={ourPeople ? changePage : (idx) => {
              if (typeof window !== 'undefined') {
                window.location.href = `${window.location.href.split('?')[0]}?page=${idx}#people`
                document.querySelector('#people').scrollIntoView()
                // window.scrollTo({
                //   top: document.querySelector('#people'),
                //   behavior: 'smooth'
                // })
              }
              // router.query.page = idx
              // router.replace(router)
            }}
          />
        </Container>
      )}
    </Container>
  )
}

PeopleGrid.propTypes = {}

export default PeopleGrid
