import { ComponentMap } from 'constants/components'

import Spacer from 'basics/Spacer'

const Sizes = Object.freeze({
  Headline: {
    size: 10,
    mdSize: 10
  },
  default: {
    size: 20,
    mdSize: 13
  }
})

const SupportingContentBlock = ({ type, data }) => {
  const Component = ComponentMap[type]
  const spacerSize = Sizes[type] || Sizes.default
  if (!Component) {
    return null
  }

  return (
    <>
      <Component data={data} />
      <Spacer {...spacerSize} />
    </>
  )
}

export default SupportingContentBlock
