import styles from './BoardLinks.module.scss'
import { ClassNames } from 'services/classname.service.js'
import Link from 'basics/Link'

const BoardLinks = ({ className }) => {
  return (
    <div className={ClassNames([styles['board-links'], className])}>
      <div className={styles['board-links__section']}>
        <h2>Board Members</h2>
      </div>
      <div className={styles['board-links__section']}>
        <h3>
          <Link href="/parent-company-board-members">Parent Company Board Members</Link>
        </h3>
      </div>
      <div className={styles['board-links__section']}>
        <h3>
          <a href="/first-business-bank-board-members">First Business Bank Board Members</a>
        </h3>
      </div>
      <div className={styles['board-links__section']}>
        <h3>
          <Link href="/advisory-board-members">Advisory Board Members</Link>
        </h3>
        <p className="mt-1">
          <Link href="/south-central-wisconsin-advisory-board-members">South Central Wisconsin</Link>
          &nbsp;&nbsp;|&nbsp;
          <Link href="/southeast-wisconsin-advisory-board-members">Southeast Wisconsin</Link>
          &nbsp;&nbsp;|&nbsp;
          <Link href="/northeast-wisconsin-advisory-board-members">Northeast Wisconsin</Link>
          &nbsp;&nbsp;|&nbsp;
          <Link href="/kansas-city-advisory-board-members">Kansas City</Link>
        </p>
      </div>
    </div>
  )
}

BoardLinks.propTypes = {}

export default BoardLinks
