import PropTypes from 'prop-types'
import styles from './PeopleFeature.module.scss'
import { ClassNames } from '../../services/classname.service'
import Headline from '../../components/Headline'
import PeopleGrid from 'features/PeopleGrid'
import { Flatten, PickFirst } from 'services/utils'
import Spacer from 'basics/Spacer'
import GridContainer from 'containers/GridContainer'

const PeopleFeature = ({
  className,
  people = [],
  gapSize = 32,
  layout,
  headline,
  level,
  subheadline,
  data = {},
  narrow = true
}) => {
  const colors = ['green', 'plum', 'blue', 'orange']
  let idx = -1
  if (data.people) {
    const peopleList = Flatten(data.people[0]?.person) || {}
    people = Object.values(peopleList)
    people = people.map((person) => {
      person = Flatten(person)
      idx = idx + 1 < colors.length ? idx + 1 : 0
      const bioImage = Flatten(PickFirst(person.bioImage || {})) || {}
      const image = PickFirst(PickFirst(bioImage.image)?.image) || {}
      return {
        firstName: person.firstName,
        lastName: person.lastName,
        title: person.title,
        company: (PickFirst(person.company) || {})?.name,
        image: bioImage?.fileName || image?.fileName || '',
        isCutout: bioImage.cutout,
        locations: person.associatedLocations,
        color: colors[idx],
        link: PickFirst(person.pageBase)?.url
      }
    })
  }
  if (data.headline) {
    headline = data.headline
    level = data.level || 2
  }
  if (data.subheadline) {
    subheadline = data.subheadline
  }
  return (
    <>
      {headline && (
        <>
          <Headline level={level} headline={headline} narrow={narrow}>
            {subheadline}
          </Headline>
          <Spacer size={8} />
        </>
      )}

      <PeopleGrid people={people} level={level} paginate={true} />
    </>
  )
}
PeopleFeature.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  people: PropTypes.array
}

export default PeopleFeature
