import styles from './ImageGrid.module.scss'
import { nanoid } from 'nanoid'
import Image from 'basics/Image'
import Spacer from 'basics/Spacer'
import Container from 'containers/Container/Container'
import FlexContainer from 'containers/FlexContainer'
import { ClassNames } from '../../services/classname.service'

const ImageGrid = ({ className = '', data = {} }) => {
  const { images = [], headline, subheadline } = data
  if (!images || (images && images.length === 0)) {
    return null
  }
  return (
    <Container className={ClassNames([styles['ds-image-grid'], 'mb-20 md-mb-13'])}>
      {headline && (
        <>
          <h2>{headline}</h2>
          {subheadline && (
            <div class="intro-paragraph">
              <p>{subheadline}</p>
            </div>
          )}
          <Spacer size={16} mdSize={10} />
        </>
      )}
      <FlexContainer
        flexDir="row"
        flexWrap="noWrap"
        justify="space-between"
        className={styles['flex-container']}
      >
        {images.map((image) => (
          <Image
            src={image.image[0]?.slug}
            alt={image.image[0]?.metadata?.alt || ''}
            key={nanoid()}
            className={ClassNames(['supporting-image', className])}
          />
        ))}
      </FlexContainer>
    </Container>
  )
}

ImageGrid.propTypes = {}

export default ImageGrid
