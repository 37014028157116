import PropTypes from 'prop-types'
import { ClassNames } from 'services/classname.service'
import Image from 'basics/Image'
import Heading from 'basics/Heading'
import Spacer from 'basics/Spacer'
import Tags from 'components/Tags'
import { PickFirst } from 'services/utils'
import styles from './PersonCard.module.scss'
import { nanoid } from 'nanoid'

const PersonCard = ({
  className,
  name,
  title,
  company,
  link,
  shortBio,
  locations = [],
  image,
  isCutout = false,
  color = 'none',
  theRest,
  leadership,
  seniorLeadership
}) => {
  // const personName = Parse(FormatSuperscript(name))
  return (
    <article
      className={ClassNames([
        styles['ds-person-card'],
        styles[`ds-person-card--${color}`],
        className
      ])}
      {...theRest}
    >
      <div className={styles['ds-person-card__content']}>
        <div className={ClassNames([styles['ds-person-card__text'], 'display-flex'])}>
          <Heading level={2}>
            {link && (
              <a href={`/${link}`} className="chevron">
                {name}
              </a>
            )}
            {!link && name}
          </Heading>

          <Spacer size={3} />

          <p>{title}</p>

          <Spacer size={3} />

          <p className="mb-0 font-small">{company}</p>

          {shortBio && (
            <p className="mb-0 font-small" dangerouslySetInnerHTML={{ __html: shortBio }} />
          )}

          {locations && locations.length > 0 && (
            <div className={styles['ds-person-card__locations']}>
              <ul>
                {locations.map((location) => (
                  <li className="font-weight-bold font-small" key={nanoid()}>
                    <a href={`/${PickFirst(location.pageBase)?.url}`} className="chevron">
                      {location.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {seniorLeadership && (
            <Tags className="mt-2" alignment="flex-start" tags={[{ name: 'Senior Leadership Team' }]} />
          )}
          {leadership && (
            <Tags className="mt-2" alignment="flex-start" tags={[{ name: 'Leadership Team' }]} />
          )}
        </div>
        <div
          className={ClassNames([
            styles['ds-person-card__image'],
            styles[`ds-person-card--${isCutout ? 'cutout' : 'normal'}`]
          ])}
        >
          {link && (
            <a href={`/${link}`}>
              <span className="sr-only">{name}</span>
              <Image src={image} className={ClassNames([styles['ds-image']])} alt={name} />
            </a>
          )}
          {!link && <Image src={image} className={ClassNames([styles['ds-image']])} />}
        </div>
      </div>
    </article>
  )
}

PersonCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  locations: PropTypes.array,
  image: PropTypes.string,
  color: PropTypes.oneOf(['none', 'blue', 'green', 'orange', 'plum'])
}

export default PersonCard
