import PropTypes from 'prop-types'
import Stat from 'components/Stat'
import FeaturedLayout from 'layouts/FeaturedLayout'
import { PickFirst } from 'services/utils'
import { nanoid } from 'nanoid'

const StatBlock = ({ className, headline, blocks = [], data = {} }) => {
  const { stats = [], headline: dynamicHeadline } = data

  return (
    <FeaturedLayout
      blocks={stats}
      headline={dynamicHeadline}
      onRender={({ block, featured = false }) => {
        const color = Array.isArray(block.color) ? PickFirst(block.color)?.shortName : block.color
        return (
          <Stat
            key={nanoid()}
            label={block.label}
            statOrNumber={block.statOrNumber}
            subscript={block.subscript}
            color={color}
            size={featured ? 'large' : 'normal'}
          />
        )
      }}
    />
  )
}

StatBlock.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  ctas: PropTypes.array,
  layout: PropTypes.object
}

export default StatBlock
