import PropTypes from 'prop-types'

import './ContentBlock.module.scss'
import { ComponentMap } from 'constants/components'

const ContentBlock = ({ className, data: { __typename, ...theRest } }) => {
  const Component = ComponentMap[__typename]
  if (!Component) {
    return null
  }
  if (__typename === 'Image') {
    theRest.imageProps = {
      width: '438'
    }
  }
  return <Component data={theRest} />
}

ContentBlock.propTypes = {}

export default ContentBlock
